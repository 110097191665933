<template>
  <div>
    <v-form ref="documentCreateForm">
      <v-card class="ma-1 pa-1">
        <v-card-title class="pa-1">
          <span>{{ documentTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn color="success" small dark @click="save()">{{ $t("save") }}</v-btn>
        </v-card-title>

        <v-divider class="ma-2"></v-divider>

        <v-card class="mt-3">
          <v-card-title class="grey lighten-3 pa-1 pl-3">
            {{ documentName }}
            <v-spacer></v-spacer>
            <v-autocomplete
              v-if="this.$store.getters.checkPermission('create_document_for_chief')"
              :label="$t('chief_employee')"
              v-model="chief_employee"
              no-filter
              clearable
              :items="chiefEmployees"
              hide-details
              dense
              outlined
              @change="getDocumentCreate()"
            ></v-autocomplete>
            <v-text-field
              v-model="document.title"
              dense
              outlined
              :label="$t('document.title')"
              hide-details="auto"
              class="white mx-4"
              :rules="[(v) => !!v || $t('input.required'),v => (v && v.length <=100) || 'Name must be less than 100 characters ' || $t('input.required')]"
            ></v-text-field>
          </v-card-title>

          <v-card-text v-if="model">
            <v-card
              class="mt-2 mx-auto"
              outlined
              v-for="(document_detail, detail_index) in document.document_details"
              :key="detail_index"
            >
              <v-card-title class="white lighten-3 pa-1 pl-3">
                {{ $t("document.punkt") }} {{ detail_index + 1 }}
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  v-if="detail_index != 0"
                  icon
                  @click="deleteDocumentDetail(document_detail)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                  v-else-if="
                    document.document_template_id != 157 &&
                    document.document_template_id != 158
                  "
                  color="success"
                  small
                  outlined
                  @click="addDocumentDetail"
                >{{ $t("document.add_punkt") }}</v-btn>
              </v-card-title>
              <hr />
              <v-card-text class="pa-1">
                <v-row v-if="model">
                  <v-col
                    cols="12"
                    md="12"
                    v-if="document.has_employee && document.is_content_visible"
                  >
                    <vueTinymce
                      v-if="false"
                      :id="'d' + detail_index"
                      v-model="document_detail['content']"
                    ></vueTinymce>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    v-if=" ((!document.has_employee && detail_index < 1) || document.has_employee) && document.is_content_visible"
                  >
                    <editor
                      api-key="no-api-key"
                      :init="{
                        height: 240,
                        menubar: true,
                        skin: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                          'searchreplace wordcount visualblocks visualchars code fullscreen',
                          'insertdatetime media nonbreaking save table contextmenu directionality',
                          'template paste textcolor colorpicker textpattern imagetools toc help emoticons hr codesample',
                        ],
                        browser_spellcheck: true,
                        spellchecker_languages: 'Russian=ru_RU',
                        spellchecker_language: 'ru_RU',
                        language: 'ru',
                        //forced_root_block: false,
                        // force_br_newlines: true,
                        force_p_newlines: true,
                        // convert_newlines_to_brs: true,
                        // nonbreaking_force_tab: true,
                        setup: function (editor) {
                          editor.addButton('indentText', {
                            icon: 'indent',
                            onclick: function () {
                              editor.dom.addClass(editor.selection.getNode(), 'indent');
                            },
                          });
                          editor.addButton('indentRemove', {
                            icon: 'outdent',
                            onclick: function () {
                              editor.dom.removeClass(
                                editor.selection.getNode(),
                                'indent'
                              );
                            },
                          });
                        },
                        content_style: `body { font-family:Arial; font-size:14px } p{margin:0px; padding:0px;} .indent{ text-indent:40px;}`,
                        fontsize_formats: '6pt 8pt 10pt 11pt 12pt 13pt 14pt 16pt 18pt 20pt 24pt 26pt 30pt 32pt 36pt',
                        toolbar: ['table print fullscreen | preview | undo redo | fontsizeselect | bold italic underline strikethrough subscript superscript | forecolor backcolor | alignleft aligncenter alignright alignjustify numlist bullist | indentText indentRemove ltr rtl | link charmap removeformat anchor codesample | pagebreak nonbreaking '],
                        toolbar_sticky: true,
                        toolbar_sticky_offset: 108,
                      }"
                      v-model="document_detail['content']"
                    />
                    <vueTinymce
                      v-if="false"
                      :id="'d' + detail_index"
                      v-model="document_detail['content']"
                    ></vueTinymce>
                  </v-col>
                </v-row>

                <v-card v-if="model && document.has_employee" class="mt-1" outlined>
                  <v-card-title class="white lighten-3 pa-1 pl-3">
                    {{ $t("employee.index") }}
                    <v-spacer></v-spacer>
                    <p class="my-1 mx-2">
                      <span
                        v-if="addEmpBtn[detail_index]"
                        @click="addEmployee(detail_index)"
                        style="
                          color: #1e88e5;
                          cursor: pointer;
                          text-decoration: underline;
                        "
                      >{{ successMessage }}</span>
                      <span
                        class="ml-1"
                        v-if="errorEmp[detail_index]"
                        style="color: #e53935"
                      >{{ errorEmpMessage }}</span>
                      <v-btn
                        color="success"
                        class="ml-2"
                        dark
                        @click="addEmployee(detail_index)"
                        v-if="addEmpBtn[detail_index] && !errorEmp[detail_index]"
                      >
                        <!-- <v-icon outlined left>mdi-plus</v-icon> -->
                        {{ $t("document.add_employee") }}
                      </v-btn>
                    </p>
                    <v-form @keyup.native.enter="addEmployee(detail_index)">
                      <v-text-field
                        @keyup="getEmployee(detail_index)"
                        v-model="employeeTabel[detail_index]"
                        :id="'addEmployeeTabel' + detail_index"
                        dense
                        outlined
                        type="text"
                        hide-details
                        label="Tabel raqami"
                        style="max-width: 170px; min-width: 140px"
                        class="mr-3 white"
                      ></v-text-field>
                    </v-form>
                  </v-card-title>
                  <hr />
                  <v-card-text class="pa-1">
                    <v-data-table
                      dense
                      :headers="headers"
                      :items="document_detail.document_detail_employees"
                      class="mt-2"
                      style="border: 1px solid #aaa"
                      :disable-pagination="true"
                      hide-default-footer
                    >
                      <template v-slot:item.id="{ item }">
                        {{
                        document_detail.document_detail_employees
                        .map(function (x) {
                        return x.id;
                        })
                        .indexOf(item.id) + 1
                        }}
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-btn color="red" small text @click="deleteEmployee(item, detail_index)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>

                <v-row>
                  <v-col
                    cols="6"
                    class="py-0"
                    v-for="(attribute, i) of document_detail.document_detail_attribute_values" :key="i"
                  >
                    <v-row
                      v-if="
                        user &&     
                        user.employee &&
                        user.employee.employee_staff.find((v) => {
                          if (
                            v.staff_id == attribute.signer_staff_id ||
                            attribute.signer_staff_id == null
                          ) {
                            return true;
                          }
                        })
                      "
                    >
                      <v-col cols="12" md="12" class="py-1" v-if="attribute.type == 'string'">
                        <v-text-field
                          v-model.lazy="attribute.attribute_value"
                          dense
                          :label="attribute.attribute_name"
                          outlined
                          type="text"
                          hide-details="auto"
                          :rules="
                            attribute.required
                              ? [
                                  (v) => !!v || $t('input.required'),
                                  (v) =>
                                    (v && v.length >= attribute.value_max_length) ||
                                    attribute.value_max_length + ' ' + $t('more_than_s'),
                                  (v) =>
                                    (v && v.length <= attribute.value_min_length) ||
                                    attribute.value_min_length + ' ' + $t('and_less_s'),
                                ]
                              : []
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" class="py-1" v-if="attribute.type == 'text'">
                        <v-textarea
                          v-model.lazy="attribute.attribute_value"
                          :label="attribute.attribute_name"
                          rows="1"
                          row-height="15"
                          dense
                          outlined
                          auto-grow
                          hide-details="auto"
                          :rules="
                            attribute.required
                              ? [
                                  (v) => !!v || $t('input.required'),
                                  (v) =>
                                    (v && v.length >= attribute.value_max_length) ||
                                    attribute.value_max_length + ' ' + $t('more_than_s'),
                                  (v) =>
                                    (v && v.length <= attribute.value_min_length) ||
                                    attribute.value_min_length + ' ' + $t('and_less_s'),
                                ]
                              : []
                          "
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" md="12" class="py-1" v-if="attribute.type == 'number'">
                        <v-text-field
                          v-model.lazy="attribute.attribute_value"
                          dense
                          :label="attribute.attribute_name"
                          outlined
                          type="number"
                          hide-details="auto"
                          :rules="
                            attribute.required
                              ? [
                                  (v) => !!v || $t('input.required'),
                                  (v) =>
                                    (!!v && v >= parseInt(attribute.value_max_length)) ||
                                    attribute.value_max_length + ' dan k\'op',
                                  (v) =>
                                    (!!v && v <= parseInt(attribute.value_min_length)) ||
                                    attribute.value_min_length + ' dan kam',
                                ]
                              : []
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" class="py-1" v-if="attribute.type == 'date'">
                        <v-menu
                          v-model.lazy="menu['menu' + i + detail_index]"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model.lazy="attribute.attribute_value"
                              :label="attribute.attribute_name"
                              readonly
                              v-on="on"
                              hide-details="auto"
                              dense
                              outlined
                              :rules="
                                attribute.required
                                  ? [(v) => !!v || $t('input.required')]
                                  : []
                              "
                              placeholder="YYYY-MM-DD"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            no-title
                            v-model="attribute.attribute_value"
                            @input="menu['menu' + i + detail_index] = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="12" class="py-1" v-if="attribute.type == 'datetime'">
                        <v-text-field
                          v-model.lazy="attribute.attribute_value"
                          :label="attribute.attribute_name"
                          hide-details="auto"
                          type="datetime-local"
                          dense
                          outlined
                          :rules="
                            attribute.required ? [(v) => !!v || $t('input.required')] : []
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" class="py-1" v-if="attribute.type == 'money'">
                        <v-text-field
                          v-model.lazy="attribute.attribute_value"
                          :label="attribute.attribute_name"
                          hide-details="auto"
                          dense
                          v-money="money"
                          outlined
                          :rules="
                            attribute.required ? [(v) => !!v || $t('input.required')] : []
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" class="py-1" v-if="attribute.type == 'checkbox'">
                        <v-checkbox
                          class="ma-1"
                          v-model.lazy="attribute.attribute_value"
                          hide-details="auto"
                          :label="attribute.attribute_name"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" md="12" class="py-1" v-if="attribute.type == 'list'">
                        <v-autocomplete
                          clearable
                          :label="attribute.attribute_name"
                          v-model.lazy="attribute.attribute_value"
                          @keyup="
                            getTableList(i + '_' + detail_index, attribute.table_list_id)
                          "
                          @click="
                            getTableList(i + '_' + detail_index, attribute.table_list_id)
                          "
                          :search-input.sync="searchTable[i + '_' + detail_index]"
                          :items="tableLists[i + '_' + detail_index]"
                          :rules="
                            attribute.required ? [(v) => !!v || $t('input.required')] : []
                          "
                          hide-details
                          dense
                          outlined
                          full-width
                          item-text="search"
                          item-value="id"
                          :loading="isLoading"
                        >
                          <!-- <template v-slot:selection="{ item }">
                            <v-chip color="white" class="pa-1 ma-0">
                              <span v-text="item.sear"></span>
                            </v-chip>
                          </template>-->
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title v-text="item.search"></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card
              class="mt-2 mx-auto"
              outlined
              v-if="document.document_template.select_department || document.document_template.select_staff"
            >
              <v-card-title class="white lighten-3 pa-1 pl-3">
                <v-responsive min-width="400">
                  <v-autocomplete
                    class="my-2"
                    clearable
                    v-model="document.department2_id"
                    :items="departmentList"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || $t('input.required')]"
                    hide-details="auto"
                    :label="$t('department_id')"
                    dense
                    outlined
                    v-if="document.document_template.select_department"
                  ></v-autocomplete>
                  <v-autocomplete
                    clearable
                    v-model="document.staff_id"
                    :items="
                      document.staff
                        ? [document.staff].concat(staff)
                        : staff
                    "
                    :item-text="'department_name_' + document.locale"
                    item-value="id"
                    :label="$t('staff_id')"
                    :rules="[(v) => !!v || $t('input.required')]"
                    hide-details="auto"
                    dense
                    outlined
                    class="my-2"
                    v-if="document.document_template.select_staff"
                  >
                    <template v-slot:selection="{ item }">
                      <v-row class="ma-0 pa-0" style="font-size: 16px">
                        <v-col
                          cols="12"
                          class="ma-0 pa-0"
                        >{{ item["department_name_" + $i18n.locale] }}</v-col>
                        <v-col
                          cols="12"
                          class="ma-0 pa-0"
                        >{{ item["position_name_" + $i18n.locale] }}</v-col>
                      </v-row>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-row
                        style="
                          border-bottom: 1px solid #ccc;
                          font-size: 14px;
                          max-width: 700px;
                        "
                        class="ma-0 pa-0"
                      >
                        <v-col
                          cols="12"
                          class="ma-0 pa-0"
                        >{{ item["department_name_" + $i18n.locale] }}</v-col>
                        <v-col
                          cols="12"
                          class="ma-0 pa-0"
                        >{{ item["position_name_" + $i18n.locale] }}</v-col>
                      </v-row>
                    </template>
                  </v-autocomplete>
                </v-responsive>
                <span
                  class="ml-1"
                  v-if="errorDocRel"
                  style="color: #e53935"
                >{{ errorDocRelMessage }}</span>
              </v-card-title>
            </v-card>

            <v-card class="mt-2 mx-auto" outlined>
              <v-card-title class="white lighten-3 pa-1 pl-3">
                {{ $t("document.add_parent_document") }}
                <v-spacer></v-spacer>
                <span
                  class="ml-1"
                  v-if="errorDocRel"
                  style="color: #e53935"
                >{{ errorDocRelMessage }}</span>
                <v-autocomplete
                  clearable
                  :label="$t('document.document_number')"
                  v-model="parent_document_id"
                  @keyup="getDocumentList"
                  id="documentRelation"
                  :search-input.sync="search"
                  :items="documentsList"
                  hide-details
                  dense
                  small-chips
                  outlined
                  item-text="document_number"
                  item-value="id"
                  style="max-width: 230px"
                >
                  <template v-slot:selection="{ item }">
                    {{
                    item.document_number
                    }}
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <span>
                        <v-list-item-title
                          v-text="
                            item.document_number +
                            ' ' +
                            item.employee['firstname_' + language] +
                            ' ' +
                            item.employee['lastname_' + language]
                          "
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.document_template['name_' + $i18n.locale]"
                        ></v-list-item-subtitle>
                      </span>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-btn
                  color="success"
                  class="ml-2"
                  small
                  icon
                  title
                  outlined
                  @click="addDocumentParent"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-card-title>
              <hr />
              <v-card-text class="pt-0">
                <v-simple-table
                  v-if="documentParents && documentParents.length"
                  dense
                  class="mt-2"
                  style="border: 1px solid #aaa"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">#</th>
                        <th class="text-left">{{ $t("document.document_number") }}</th>
                        <th class="text-left">{{ $t("document.document_date") }}</th>
                        <th class="text-left">{{ $t("document.document_name") }}</th>
                        <th class="text-left">{{ $t("document.creator") }}</th>
                        <th class="text-left"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in documentParents" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.document_number }}</td>
                        <td>{{ item.document_date }}</td>
                        <td>{{ item.document_name }}</td>
                        <td>{{ item.creator }}</td>
                        <td class="text-center" width="50px">
                          <v-icon
                            class="px-1"
                            color="error"
                            @click="deleteDocumentRelation(item)"
                          >mdi-delete</v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>

            <v-card class="mt-2 mx-auto" outlined>
              <v-card-title class="white lighten-3 pa-1 pl-3">
                {{ $t("files") }}
                <v-spacer></v-spacer>
                <v-btn color="success" small title outlined @click="getDocumentFile">
                  <v-icon left style="font-size: 28px">mdi-file-upload-outline</v-icon>
                  {{ $t("uploadFiles") }}
                </v-btn>
              </v-card-title>
              <hr />
              <v-card-text class="pt-0">
                <v-simple-table
                  v-if="documentFiles && documentFiles.length"
                  dense
                  class="mt-2"
                  style="border: 1px solid #aaa"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">#</th>
                        <th class="text-left">{{ $t("document.file_name") }}</th>
                        <th class="text-left"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in documentFiles" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.file_name }}</td>
                        <td class="text-lg-right" width="100px">
                          <v-icon class="px-1" color="success">mdi-eye</v-icon>
                          <v-icon class="px-1" color="error" @click="deleteFile(item)">mdi-delete</v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>

        <v-card v-if="model" class="mt-3">
          <v-card-title class="grey lighten-3 pa-1 pl-3">
            {{ $t("document.signers") }}
            <v-spacer></v-spacer>
            <v-autocomplete
              clearable
              :label="$t('signerGroup.signer_group_id')"
              v-model.lazy="signer_group_id"
              @change="addSignerGroup(signer_group_id)"
              :items="signer_groups"
              hide-details
              class="white mr-12"
              dense
              outlined
              item-value="id"
              :loading="isLoading"
              style="max-width: 350px"
              :rules="signer_groups.length ? [(v) => !!v || $t('input.required')] : []"
            >
              <template v-slot:selection="{ item }">{{ item["name_" + $i18n.locale] }}</template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item['name_' + $i18n.locale]"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-btn
              class="mr-4"
              v-if="document.add_signer"
              color="primary"
              dense
              icon
              tile
              outlined
              @click="getSigners"
            >
              <v-icon>mdi-account-multiple-check-outline</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-data-table
              dense
              :headers="headerSigner"
              :items="document.document_signers"
              class="ma-1"
              style="border: 1px solid #aaa"
              :disable-pagination="true"
              hide-default-footer
            >
              <template v-slot:item.id="{ item }">
                {{
                document.document_signers
                .map(function (x) {
                return x.id;
                })
                .indexOf(item.id) + 1
                }}
              </template>
              <template v-slot:item.staff_name="{ item }">
                <strong>{{ item.department_code + " " + item.staff_name }}</strong>
                <br />
                <strong>{{ item.staff_position }}</strong>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn icon color="error" v-if="item.visible" class="mr-4">
                  <v-icon @click="deleteSigner(item)">mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card>
    </v-form>

    <v-dialog v-model="modalDocumentFile" persistent width="800">
      <v-card>
        <v-card-title class="grey lighten-3">
          {{ $t("document.add_file") }}
          <v-spacer></v-spacer>
          <v-btn color="red" outlined x-small fab class @click="modalDocumentFile = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form @keyup.native.enter="save" ref="dialogForm">
            <v-row>
              <v-col
                cols="10"
                style="min-width: 100px; max-width: 100%"
                class="flex-grow-1 flex-shrink-0"
              >
                <label for>{{ $t("document.files") }}</label>
                <v-file-input
                  v-model="selectFiles"
                  :rules="[
                    (files) => {
                      let allowedExtensions = /(\.pdf|\.xls|\.xlsx|\.xlsm|\.doc|\.docx|\.ppt|\.pptx|\.jpg|\.jpeg|\.png)$/i;
                      let error = false;
                      files.forEach((file) => {
                        if (!allowedExtensions.exec(file.name)) {
                          error = true;
                        }
                      });
                      return !error || $t('requiredDoc');
                    },
                    (files) =>
                      !files ||
                      !files.some((file) => file.size > 60000000) ||
                      $t('requiredsize'),
                  ]"
                  outlined
                  dense
                  multiple="multiple"
                  prepend-icon
                  append-icon="mdi-file-pdf-box-outline"
                  accept=".xls,.xlsx,.doc,.docx,.ppt,.pptx,.pdf,image/png,image/jpg,image/jpeg,image/bmp"
                  small-chips
                  show-size
                  hide-details="auto"
                ></v-file-input>
              </v-col>
              <v-col cols="2" style="min-width: 100px" class="px-0">
                <v-btn
                  :disabled="!selectFiles || selectFiles.length == 0"
                  class="mt-6"
                  color="success"
                  block
                  @click="addFiles"
                >+</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="signatories" persistent width="800">
      <v-card>
        <v-card-title class="grey lighten-3">
          {{ $t("document.add_signer") }}   
          <v-spacer></v-spacer>
          <v-btn color="red" outlined x-small fab class @click="signatories = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="addSignerForm">
            <v-row>
              <v-col
                cols="12"
                style="min-width: 100px; max-width: 100%"
                class="flex-grow-1 flex-shrink-0 px-1"
              >
                <v-autocomplete
                  :label="$t('document.department')"
                  v-model="new_signer.department"
                  @keyup="getSigners"
                  no-filter
                  class="pa-0"
                  clearable
                  :items="departments"
                  :search-input.sync="search"
                  item-value="id"
                  item-text="text"
                  :rules="[(v) => !!v || $t('input.required')]"
                  hide-details
                  dense 
                  outlined
                  :loading="isLoading"
                >
                  <template v-slot:selection="{ item }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.code + ' ' + item.department_name"></v-list-item-title>
                      
                      <v-list-item-subtitle>
                        {{item.first_name +" " + item.last_name + " " + item.position_name}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.code + ' ' + item.department_name"></v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                        item.first_name +
                        " " +
                        item.last_name +
                        " " +
                        item.position_name
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="4"
                style="min-width: 100px; max-width: 100%"
                class="flex-grow-1 flex-shrink-0 px-1"
              >
                <v-autocomplete
                  :label="$t('document.action_type')"
                  v-model="new_signer.action_type_id"
                  class="pa-0"
                  :items="action_types"
                  item-value="id"
                  :item-text="'name_' + $i18n.locale"
                  :rules="[(v) => !!v || $t('input.required')]"
                  hide-details
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="4"
                style="min-width: 100px; max-width: 100%"
                class="flex-grow-1 flex-shrink-0 px-1"
              >
                <v-text-field
                  v-if="
                    new_signer.action_type_id == 1 ||
                    new_signer.action_type_id == 2 ||
                    new_signer.action_type_id == 8 ||
                    new_signer.action_type_id == 9 ||
                    new_signer.action_type_id == 10 ||
                    new_signer.action_type_id == 12
                  "
                  v-model="new_signer.sequence"
                  :label="$t('documentTemplates.sequence')"
                  :rules="[
                    (v) => !!v || $t('input.required'),
                    (v) => (!!v && v >= 2) || '2 dan k\'op',
                    (v) => (!!v && v <= 70) || '70 dan kam',
                  ]"
                  type="number"
                  hide-details="auto"
                  dense
                  outlined
                  full-width
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                style="min-width: 100px; max-width: 100%"
                class="flex-grow-1 flex-shrink-0 px-1"
              >
                <v-text-field
                  v-model.lazy="new_signer.due_date"
                  :label="$t('document.due_date')"
                  hide-details="auto"
                  type="datetime-local"
                  dense
                  outlined
                  clearable
                  placeholder="YYYY-MM-DD"
                ></v-text-field>
              </v-col>
              <v-col
                cols="2"
                style="min-width: 100px; max-width: 100%"
                class="flex-grow-1 flex-shrink-0 px-1"
              >
                <v-btn color="success" outlined block @click="addSigners">
                  {{
                  $t("add")
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loading" width="300" hide-overlay>
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t("loadingText") }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const axios = require("axios").default;
const moment = require("moment");
import Swal from "sweetalert2";
import vueTinymce from "@/components/TinymceVue";
import Editor from "@tinymce/tinymce-vue";
export default {
  components: {
    vueTinymce,
    editor: Editor,
  },
  data() {
    return {
      staff: [],
      departmentList: [],
      search_staff: "",
      chief_employee: null,
      chiefEmployees: [],
      editorOption: {},
      loading: false,
      documentTitle: "",
      document: {
        document_type_id: null,
        document_template_id: null,
        department2_id: null,
        staff_id: null,
        document_details: [],
        document_signers: []
      },
      documentName: "",
      document_detail_attributes: [],
      attributes: [],
      headers: [
        { text: "#", value: "id", align: "center", width: 30, sortable: false },
        {
          text: this.$t("employee.tabel"),
          value: "tabel_number",
          align: "center",
          sortable: false
        },
        { text: this.$t("employee.info"), value: "fio", sortable: false },
        {
          text: this.$t("department.index"),
          value: "department",
          sortable: false
        },
        {
          text: this.$t("position.index"),
          value: "position",
          sortable: false
        },
        // {
        //   text: this.$t("tariffScale.category"),
        //   value: "tariffScale",
        //   sortable: false,
        // },
        {
          text: this.$t("actions"),
          value: "actions",
          width: 50,
          align: "center",
          sortable: false
        }
      ],
      headerSigner: [
        { text: "#", value: "id", align: "center", width: 30, sortable: false },
        {
          text: this.$t("document.staff"),
          value: "staff_name",
          sortable: false
        },
        {
          text: this.$t("document.action_type"),
          value: "action_type",
          sortable: false
        },
        {
          text: this.$t("document.signer_sequence"),
          value: "sequence",
          sortable: true
        },
        {
          text: this.$t("employee.info"),
          value: "fio",
          sortable: true
        },
        {
          text: "",
          value: "action",
          sortable: false,
          width: 30
        }
      ],
      employees: [],
      employee_signers: [],
      employeeTabel: [],
      model: false,
      addEmpBtn: [false],
      errorEmp: [false],
      errorDocRel: false,
      errorEmpMessage: "",
      errorDocRelMessage: "",
      successMessage: "",
      disabled: false,
      menu: [],
      action_type: {
        id: 3,
        ru: "Отправитель",
        uz_cyril: "Юборувчи",
        uz_latin: "Yuboruvchi"
      },
      action_type_receiver: {
        id: 3,
        ru: "Согласование",
        uz_cyril: "Розилик",
        uz_latin: "Rozilik"
      },
      action_types: [
        {
          id: 1,
          name_uz_latin: "Rozilik",
          name_uz_cyril: "Розилик",
          name_ru: "Согласование"
        },
        {
          id: 2,
          name_uz_latin: "Tasdiq",
          name_uz_cyril: "Тасдиқ",
          name_ru: "Утверждение"
        },
        {
          id: 4,
          name_uz_latin: "Ijro uchun",
          name_uz_cyril: "Ижро учун",
          name_ru: "Для исполнения"
        },
        {
          id: 5,
          name_uz_latin: "Ma`lumot uchun",
          name_uz_cyril: "Маълумот учун",
          name_ru: "Для информации"
        },
        {
          id: 8,
          name_uz_latin: "Komissiya a'zolari",
          name_uz_cyril: "Комиссия аъзолари",
          name_ru: "Члены комиссии"
        },
        {
          id: 9,
          name_uz_latin: "Komissiya raisi",
          name_uz_cyril: "Комиссия раиси",
          name_ru: "Председатель комиссии"
        },
        {
          id: 10,
          name_uz_latin: "Komissiya kotibi",
          name_uz_cyril: "Комиссия котиби",
          name_ru: "Секретарь комиссии"
        },
        {
          id: 11,
          name_uz_latin: "Nazoratchi",
          name_uz_cyril: "Назоратчи",
          name_ru: "Контрольщик"
        },
        {
          id: 12,
          name_uz_latin: "Kuzatuvchi (Komissiya)",
          name_uz_cyril: "Кузатувчи (Комиссия)",
          name_ru: "Наблюдатель (комиссии)"
        },
        {
          id: 13,
          name_uz_latin: "Hujjat yaratuvchisi",
          name_uz_cyril: "Ҳужжат яратувчиси",
          name_ru: "Создатель документа"
        }
      ],
      tableLists: [],
      modalDocumentFile: false,
      signatories: false,
      selectFiles: [],
      documentFiles: [],
      formData: [],
      doc_id: 0,
      departments: [],
      new_signer: {
        action_type_id: 1
      },
      isLoading: false,
      tableLoading: [],
      search: "",
      searchTable: [],
      documentsList: [],
      parent_document_id: null,
      documentParents: [],
      signer_groups: [],
      signer_group_id: null,
      
      money: {
        decimal: ".",
        thousands: ",",
        masked: false,
        precision: 2
      },

      branch_d:0
    };
  },
  computed: {
    screenHeight() {
      return window.innerHeight - 170;
    },
    language() {
      return this.$i18n.locale == "ru" ? "uz_cyril" : this.$i18n.locale;
    },
    user() {
      let localStorage = window.localStorage;
      return JSON.parse(localStorage.getItem("user"));
    },

    
  },
  methods: {
    branch(){
      let branch_x = JSON.parse(window.localStorage.getItem("user"));
      this.branch_d = branch_x.employee.employee_staff[0].staff.branch_id
      console.log(this.branch_d,"ushbu user branchi keldi")
      return this.branch_d
    },
    getDepartments() {    
      axios
        .post(
          this.$store.state.backend_url + "api/departments/get-department",
          {
            language: this.$i18n.locale,
          }
        )
        .then(response => {
          console.log(this.response.data)  
          this.departmentList = response.data.map(v => {
            v.name = v.code + " " + v.name;
            return v;
          });

          
        })
        .catch(error => {
          console.log(error);
        });
    },
    getStaff() {
      this.isLoading = true;
      axios
        .post(this.$store.state.backend_url + "api/department/get-staffs", {
          search: this.search_staff,
          language: this.$i18n.locale
        })
        .then(res => {
          this.staff = res.data.map(v => {
            v["department_name_" + this.$i18n.locale] =
              v.department_code +
              " " +
              v["department_name_" + this.$i18n.locale];
            return v;
          });
          this.isLoading = false;
        })
        .catch(err => {
          this.isLoading = false;
          console.error(err);
        });
    },
    addSigners() {
      // console.log(this.new_signer.action_type_id);
      let item = this.departments.find(v => {
        return this.new_signer.department == v.id ? v : null;
      });

      if (
        !this.document.document_signers.filter(v => {
          if (v.staff_id == item.manager_staff_id && v.action_type_id != 4) {
            return true;
          }
        }).length &&
        this.$refs.addSignerForm.validate()
      ) {
        if (this.document.document_template_id == 1) {
          this.document.department_id = item.id;
        }
        if (item.manager_staff.employees.length)
          this.document.document_signers.push({
            id: Date.now() + Math.floor(Math.random() * 1000),
            document_id: this.document.id,
            staff_id: item.manager_staff_id,
            action_type_id: this.new_signer.action_type_id,
            due_date: this.new_signer.due_date,
            taken_datetime: null,
            sequence:
              this.new_signer.action_type_id == 1 ||
              this.new_signer.action_type_id == 2 ||
              this.new_signer.action_type_id == 8 ||
              this.new_signer.action_type_id == 9 ||
              this.new_signer.action_type_id == 10
                ? this.new_signer.sequence
                : 0,
            sign_type: 1,
            signer_group_id: 0,
            visible: true,
            department_code: item.code,
            staff_name: item.department_name,
            staff_position:
              item.manager_staff.position["name_" + this.$i18n.locale],
            action_type: this.action_types.find(v => {
              if (v.id == this.new_signer.action_type_id) return v;
            })["name_" + this.$i18n.locale],
            fio: item.manager_staff.employees.length
              ? this.bosh(item.manager_staff.employees[0][
                  "firstname_" + this.language
                ]) +
                "." +
                (item.manager_staff.employees[0][
                  "middlename_" + this.language
                ] == " "
                  ? " "
                  : this.bosh(item.manager_staff.employees[0][
                      "middlename_" + this.language
                    ]) + ". ") +
                item.manager_staff.employees[0]["lastname_" + this.language]
              : this.$t("document.vacancy")
          });
      }
      this.signatories = false;
    },
    bosh(item){
      let ar = ["Ch", "Sh","CH", "SH", "O`", "O'", "G`", "G'"];
      if(ar.find(v => v == item.substr(0,2))) {
        return item.substr(0,2);
      }
      else {
        return item.substr(0,1);
      }
    },
    addEmployee(key) {
      if (
        this.document.document_details[key].document_detail_employees.filter(
          v => {
            if (v.tabel_number == this.employeeTabel[key]) {
              return true;
            }
          }
        ).length &&
        this.employeeTabel[key]
      ) {
        this.successMessage = "";
        this.errorEmp[key] = true;
        this.errorEmpMessage = "Bu tabel ro'yxatga qo'shildi";
      } else {
        this.loading = true;
        this.document.document_details[key].document_detail_employees.push({
          id: Date.now(),
          document_detail_id: this.document.document_details[key].id,
          employee_id: this.employee.id,
          tabel_number: this.employeeTabel[key],
          description: "",
          fio:
            this.employee.firstname +
            " " +
            this.employee.lastname +
            " " +
            this.employee.middlename,
          department: this.staff.department["name_" + this.$i18n.locale],
          position: this.staff.position["name_" + this.$i18n.locale],
          tariffScale: this.employee.employee_staff[0].tariff_scale
            ? this.employee.employee_staff[0].tariff_scale.category
            : ""
        });
        this.employee_signers.forEach(element => {
          if (
            !this.document.document_signers.filter(v => {
              if (v.staff_id == element.manager_staff_id) {
                return true;
              }
            }).length &&
            this.document.add_parent
          ) {
            if (
              element.manager_staff &&
              element.manager_staff.employees.length
            ) {
              this.document.document_signers.push({
                id: Date.now() + Math.floor(Math.random() * 1000),
                document_id: this.document.id,
                staff_id: element.manager_staff_id,
                action_type_id: 3,
                taken_datetime: moment()
                  .add(1, "days")
                  .format("YYYY-MM-DD"),
                sequence: element.department_type.sequence <= 2 ? 99 : 100,
                sign_type: 1,
                visible: true,
                department_code: element.department_code,
                staff_name: element["name_" + this.$i18n.locale],
                staff_position:
                  element.manager_staff.position["name_" + this.$i18n.locale],
                action_type: this.action_type[this.$i18n.locale],
                fio: element.manager_staff.employees.length
                  ? this.bosh(element.manager_staff.employees[0][
                      "firstname_" + this.language
                    ]) +
                    "." +
                    (element.manager_staff.employees[0][
                      "middlename_" + this.language
                    ] == " "
                      ? " "
                      : this.bosh(element.manager_staff.employees[0][
                          "middlename_" + this.language
                        ]) + ". ") +
                    element.manager_staff.employees[0][
                      "lastname_" + this.language
                    ]
                  : this.$t("document.vacancy")
              });
            }
          }
        });
        this.employeeTabel[key] = "";
        this.successMessage = "";
        this.loading = false;
        this.addEmpBtn[key] = false;
      }
    },
    addDocumentDetail() {
      this.document.document_details.push({
        id: Date.now() + Math.floor(Math.random() * 100),
        document_id: this.document.id,
        content: "",
        document_detail_attribute_values: [],
        document_detail_employees: []
      });
      let length = this.document.document_details.length - 1;
      this.document_detail_attributes.forEach(element => {
        this.document.document_details[
          length
        ].document_detail_attribute_values.push({
          id: Date.now(),
          document_detail_id: this.document.document_details[length].id,
          d_d_attribute_id: element.id,
          attribute_value: "",
          type: element.type,
          attribute_name: element["attribute_name_" + this.$i18n.locale],
          value_min_length: element.value_min_length,
          value_max_length: element.value_max_length,
          required: element.required,
          table_list_id: element.table_list_id,
          signer_staff_id: element.signer_staff_id,
          document_detail_attributes: element
        });
      });
    },
    addDocumentParent() {
      let doc = this.documentsList.find(v => {
        if (v.id == this.parent_document_id) return v;
      });

      if (
        !this.documentParents.filter(v => {
          if (v.parent_document_id == this.parent_document_id) {
            return true;
          }
        }).length
      ) {
        this.documentParents.push({
          document_id: this.document.id,
          parent_document_id: this.parent_document_id,
          creator:
            doc.employee["lastname_" + this.language] +
            " " +
            doc.employee["firstname_" + this.language] +
            " " +
            doc.employee["middlename_" + this.language],
          document_number: doc.document_number,
          document_date: doc.created_at.substr(0, 10),
          document_name: doc.document_template["name_" + this.$i18n.locale]
        });
      }
      // console.log(this.documentParents);
    },
    addFiles() {
      this.selectFiles.forEach((v, i) => {
        this.formData.append("files[]", v);
        this.documentFiles.push({
          id: Date.now() + Math.floor(Math.random() * 1000),
          file_name: v.name
        });
      });
      this.selectFiles = [];
      this.modalDocumentFile = false;
    },
    addSignerGroup(id) {
      this.document.document_signers = this.document.document_signers.filter(
        v => {
          if (!v.signer_group_id) {
            return v;
          }
        }
      );
      let signer_group = this.signer_groups.find(v => {
        if (v.id == id) return v;
      });
      signer_group &&
        signer_group.signer_group_details.map(signer_group_detail => {
          if (
            !this.document.document_signers.filter(v => {
              if (
                v.staff_id == signer_group_detail.staff_id &&
                v.action_type_id == signer_group_detail.staff_id
              ) {
                return true;
              }
            }).length
          ) {
            if (signer_group_detail.action_type_id == 2) {
              this.document.department_id =
                signer_group_detail.staff.department.id;
            }
            this.document.document_signers.push({
              id: Date.now() + Math.floor(Math.random() * 1000),
              document_id: this.document.id,
              staff_id: signer_group_detail.staff_id,
              action_type_id: signer_group_detail.action_type_id,
              taken_datetime: null,
              sequence: signer_group_detail.sequence,
              sign_type: signer_group_detail.sign_type,
              visible: false,
              signer_group_id: id,
              is_registry: signer_group_detail.is_registry,
              department_code:
                signer_group_detail.staff.department.department_code,
              staff_name:
                signer_group_detail.staff.department[
                  "name_" + this.$i18n.locale
                ],
              staff_position:
                signer_group_detail.staff.position["name_" + this.$i18n.locale],
              // action_type: signer_group_detail.action_type_id,
              action_type: this.action_types.find(v => {
                if (v.id == signer_group_detail.action_type_id) return v;
              })["name_" + this.$i18n.locale],
              fio: signer_group_detail.staff.employees.length
                ? this.bosh(signer_group_detail.staff.employees[0][
                    "firstname_" + this.language
                  ]) +
                  "." +
                  (signer_group_detail.staff.employees[0][
                    "middlename_" + this.language
                  ] == " "
                    ? " "
                    : this.bosh(signer_group_detail.staff.employees[0][
                        "middlename_" + this.language
                      ]) + ". ") +
                  signer_group_detail.staff.employees[0][
                    "lastname_" + this.language
                  ]
                : this.$t("document.vacancy")
            });
          }
        });
    },
    getDocument(id) {
      this.disabled = true;
      this.loading = true;
      axios
        .post(this.$store.state.backend_url + "api/documents/show-document", {
          id: id
        })
        .then(res => {
          this.document = res.data.document;
          if (this.document.staff) {
            this.document.staff["department_name_" + this.$i18n.locale] =
              this.document.staff["department_code"] +
              " " +
              this.document.staff["department_name_" + this.$i18n.locale];
          }
          this.signer_groups = this.document.document_template.signer_groups;
          this.documentName = this.document.document_template[
            "name_" + this.$i18n.locale
          ];
          if (
            (this.document &&
              (this.document.status < 2 || this.document.status == 11) &&
              this.document.created_employee_id == this.user.employee_id) ||
            this.$store.getters.checkPermission("edit_all_document") ||
            this.document.action_type_id == 13
          ) {
            this.model = true;
            this.document.document_details.forEach(element => {
              element.document_detail_attribute_values.map(v => {
                if (
                  v.document_detail_attributes.data_type.name_uz_latin ==
                  "Float"
                ) {
                  v.type = "number";
                } else if (
                  v.document_detail_attributes.data_type.name_uz_latin ==
                  "Boolean"
                ) {
                  v.type = "checkbox";
                } else if (
                  v.document_detail_attributes.data_type.name_uz_latin ==
                  "String"
                ) {
                  v.type = "string";
                } else if (
                  v.document_detail_attributes.data_type.name_uz_latin == "Text"
                ) {
                  v.type = "text";
                } else if (
                  v.document_detail_attributes.data_type.name_uz_latin ==
                  "Integer"
                ) {
                  v.type = "number";
                } else if (
                  v.document_detail_attributes.data_type.name_uz_latin ==
                  "Summa"
                ) {
                  v.type = "money";
                } else if (
                  v.document_detail_attributes.data_type.name_uz_latin == "Sana"
                ) {
                  v.type = "date";
                } else if (
                  v.document_detail_attributes.data_type.name_uz_latin ==
                  "Summa"
                ) {
                  v.type = "money";
                } else if (v.document_detail_attributes.data_type.id == 6) {
                  v.type = "list";
                } else {
                  v.type = "text";
                }
                v.attribute_name =
                  v.document_detail_attributes[
                    "attribute_name_" + this.$i18n.locale
                  ];
                v.value_min_length =
                  v.document_detail_attributes.value_min_length;
                v.value_max_length =
                  v.document_detail_attributes.value_max_length;
                v.table_list_id = v.document_detail_attributes.table_list_id;
                v.signer_staff_id =
                  v.document_detail_attributes.signer_staff_id;
                return v;
              });
            });
            let i = 0;
            this.document.document_details.forEach(
              (document_detail, document_detail_index) => {
                document_detail.document_detail_attribute_values.forEach(
                  (element, index) => {
                    if (element.table_lists) {
                      let colums = element.document_detail_attributes.table_list.column_name.split(
                        ", "
                      );
                      let search = "";
                      colums.forEach(colum => {
                        colum = colum.replace("locale", this.$i18n.locale);
                        search = element.table_lists[0][colum]
                          ? search + " " + element.table_lists[0][colum]
                          : search;
                      });
                      element.table_lists.map(v => {
                        v.search = search.trim().replace(/  /g, " ");
                      });
                      this.tableLists[index + "_" + document_detail_index] =
                        element.table_lists;
                    }
                    if (document_detail_index == 0) {
                      this.document_detail_attributes.push(
                        element.document_detail_attributes
                      );
                    }
                    i++;
                  }
                );
              }
            );
            // console.log(this.tableLists);

            this.document.document_details.forEach(document_detail => {
              document_detail.document_detail_attribute_values.forEach(
                document_detail_attribute_value => {
                  if (document_detail_attribute_value.table_lists) {
                    document_detail_attribute_value.attribute_value = Number(
                      document_detail_attribute_value.attribute_value
                    );
                  }
                }
              );
            });
            this.document_detail_attributes.map(v => {
              if (v.data_type.name_uz_latin == "Float") {
                v.type = "number";
              } else if (v.data_type.name_uz_latin == "Boolean") {
                v.type = "checkbox";
              } else if (v.data_type.name_uz_latin == "String") {
                v.type = "text";
              } else if (v.data_type.name_uz_latin == "Integer") {
                v.type = "number";
              } else if (v.data_type.name_uz_latin == "Sana") {
                v.type = "date";
              } else if (v.data_type.name_uz_latin == "Datetime") {
                v.type = "datetime";
              } else if (v.data_type.name_uz_latin == "Summa") {
                v.type = "money";
              } else if (v.data_type.id == 6) {
                v.type = "list";
              } else {
                v.type = "text";
              }
              return v;
            });
            this.document.document_details.forEach(element => {
              element.document_detail_employees.map(v => {
                v.tabel_number = v.employee.tabel;
                v.fio =
                  this.$i18n.locale == "ru"
                    ? v.employee["firstname_uz_cyril"] +
                      " " +
                      v.employee["lastname_uz_cyril"] +
                      " " +
                      v.employee["middlename_uz_cyril"]
                    : v.employee["firstname_" + this.$i18n.locale] +
                      " " +
                      v.employee["lastname_" + this.$i18n.locale] +
                      " " +
                      v.employee["middlename_" + this.$i18n.locale];
                v.department = v.employee.employee_staff[0]
                  ? v.employee.employee_staff[0].staff.position[
                      "name_" + this.$i18n.locale
                    ]
                  : "";
                return v;
              });
            });

            this.document.document_signers.map(v => {
              if (!this.signer_group_id) {
                this.signer_group_id = v.signer_group_id
                  ? v.signer_group_id
                  : null;
              }
              v.department_code = v.staff.department.department_code;
              v.staff_name = v.staff.department["name_" + this.$i18n.locale];
              v.staff_position = v.staff.position["name_" + this.$i18n.locale];
              v.action_type = v.action_types[0]["name_" + this.$i18n.locale];
              v.fio = v.signer_employee
                ? this.bosh(v.signer_employee["firstname_" + this.language]) +
                  "." +
                  (v.signer_employee["middlename_" + this.language] == " "
                    ? " "
                    : this.bosh(v.signer_employee["middlename_" + this.language]) + ". ") +
                  v.signer_employee["lastname_" + this.language]
                : v.employee_staffs
                ? this.bosh(v.employee_staffs.employee[
                    "firstname_" + this.language
                  ]) +
                  "." +
                  this.bosh(v.employee_staffs.employee[
                    "middlename_" + this.language
                  ]) +
                  ". " +
                  v.employee_staffs.employee["lastname_" + this.language]
                : this.$t("document.vacancy");
              v.visible =
                (v.action_type_id != 6 &&
                  !v.taken_datetime &&
                  !this.document.document_template.document_signer_templates.find(
                    val => {
                      if (v.staff_id == val.staff_id) return v;
                    }
                  ) &&
                  v.sequence != 100 &&
                  v.signer_group_id == 0) ||
                v.action_type_id == 13
                  ? true
                  : false;
            });
            this.document.has_employee = this.document.document_template.has_employee;
            this.document.add_signer = this.document.document_template.add_signer;
            this.document.add_parent = this.document.document_template.add_parent;
            this.document.is_content_visible = this.document.document_template.is_content_visible;
            this.document.is_document_relation = this.document.document_template.is_document_relation;
            this.document.document_relation.forEach(doc_relation => {
              this.documentParents.push({
                document_id: this.document.id,
                parent_document_id: doc_relation.id,
                creator:
                  doc_relation.employee["lastname_" + this.language] +
                  " " +
                  doc_relation.employee["firstname_" + this.language] +
                  " " +
                  doc_relation.employee["middlename_" + this.language],
                document_number: doc_relation.document_number,
                document_date: doc_relation.created_at.substr(0, 10),
                document_name:
                  doc_relation.document_template["name_" + this.$i18n.locale]
              });
            });
            this.loading = false;
          } else {
            Swal.fire(
              this.$t("swal_error_title"),
              this.$t("swal_error_text"),
              "error"
            );
            window.history.back();
          }

          setTimeout(() => {
            this.document.document_details.forEach(element => {
              element.content = element.content + " ";
            });
          }, 500);
          this.documentFiles = res.data.document_files;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        });
    },
    getSigners() {
      this.isLoading = true;
      axios
        .post(this.$store.state.backend_url + "api/departments/list", {
          search: this.search,
          locale: this.$i18n.locale,
          branch_id:this.branch_d
        })
        .then(res => {
          this.departments = res.data.data.map(v => {
            v.text =
              v.code +
              " " +
              v.department_name +
              " " +
              v.first_name +
              " " +
              v.last_name;
            return v;
          });
          console.log(this.departments);
          this.isLoading = false;
        })
        .catch(err => {
          console.error(err);
          this.isLoading = false;
        });
      this.signatories = true;
    },
    getDocumentCreate() {
      this.loading = true;
      axios
        .post(
          this.$store.state.backend_url + "api/document-templates/get-all",
          {
            document_template_id: this.document.document_template_id,
            language: this.$i18n.locale
          }
        )
        .then(res => {
          this.documentName = res.data["name_" + this.$i18n.locale];
          this.document.document_template = res.data;
          this.document.department_id = res.data.department_id;
          this.document.document_type_id = res.data.document_type_id;
          this.document.has_employee = res.data.has_employee;
          this.document.add_signer = res.data.add_signer;
          this.document.add_parent = res.data.add_parent;
          this.document.is_content_visible = res.data.is_content_visible;
          this.document.is_document_relation = res.data.is_document_relation;
          this.document.locale = this.$i18n.locale;
          this.signer_groups = res.data.signer_groups;
          this.document.document_number = "000000";
          this.document.document_details[0].content =
            res.data.document_detail_templates[0][
              "content_" + this.$i18n.locale
            ];
          this.document_detail_attributes =
            res.data.document_detail_templates[0].document_detail_attributes;

          this.document_detail_attributes.map(v => {
            if (v.data_type.name_uz_latin == "Float") {
              v.type = "number";
            } else if (v.data_type.name_uz_latin == "Boolean") {
              v.type = "checkbox";
            } else if (v.data_type.name_uz_latin == "String") {
              v.type = "text";
            } else if (v.data_type.name_uz_latin == "Integer") {
              v.type = "number";
            } else if (v.data_type.name_uz_latin == "Sana") {
              v.type = "date";
            } else if (v.data_type.name_uz_latin == "Datetime") {
              v.type = "datetime";
            } else if (v.data_type.name_uz_latin == "Summa") {
              v.type = "money";
            } else if (v.data_type.id == 6) {
              v.type = "list";
            } else {
              v.type = "text";
            }
            return v;
          });
          this.model = true;
          this.document.document_details[0].document_detail_attribute_values = [];
          let table_index = 0;
          let colums = [];
          // console.log(this.document_detail_attributes);
          this.document_detail_attributes.forEach(element => {
            this.document.document_details[0].document_detail_attribute_values.push(
              {
                id: Date.now(),
                document_detail_id: this.document.document_details[0].id,
                d_d_attribute_id: element.id,
                attribute_value: "",
                type: element.type,
                attribute_name: element["attribute_name_" + this.$i18n.locale],
                value_min_length: element.value_min_length,
                value_max_length: element.value_max_length,
                required: element.required,
                table_list_id: element.table_list_id,
                signer_staff_id: element.signer_staff_id,
                document_detail_attributes: element
              }
            );
          });
          this.document.document_signers = [];
          let localStorage = window.localStorage;
          let user = JSON.parse(localStorage.getItem("user"));
          let chief_employee = this.chiefEmployees.find(
            v => v.value == this.chief_employee
          );
          axios
            .post(
              this.$store.state.backend_url + "api/employees/get-employee",
              {
                tabel: chief_employee
                  ? chief_employee.tabel
                  : user.employee.tabel,
                language:
                  this.$i18n.locale == "ru" ? "uz_cyril" : this.$i18n.locale
              }
            )
            .then(resp => {
              if (this.document.document_template_id == 1) {
                this.document.department_id =
                  resp.data.parents.main_department.id;
              }
              this.document.document_signers.push({
                id: Date.now() + Math.floor(Math.random() * 1000),
                document_id: this.document.id,
                staff_id: resp.data.employee[0].staff[0].id,
                action_type_id: 6,
                taken_datetime: moment()
                  .add(1, "days")
                  .format("YYYY-MM-DD"),
                sequence: 100,
                signer_employee_id: resp.data.employee[0].id,
                status: 1,
                visible: false,
                department_code:
                  resp.data.employee[0].staff[0].department.department_code,
                staff_name:
                  resp.data.employee[0].staff[0].department[
                    "name_" + this.$i18n.locale
                  ],
                staff_position:
                  resp.data.employee[0].staff[0].position[
                    "name_" + this.$i18n.locale
                  ],
                action_type: this.action_type[this.$i18n.locale],
                fio:
                  this.bosh(resp.data.employee[0].firstname) +
                  "." +
                  this.bosh(resp.data.employee[0].middlename) +
                  ". " +
                  resp.data.employee[0].lastname
              });
              res.data.document_signer_templates.forEach(element => {
                if (element.staff.employees.length)
                  this.document.document_signers.push({
                    id: Date.now() + element.id,
                    document_id: this.document.id,
                    staff_id: element.staff_id,
                    is_registry: element.is_registry,
                    action_type_id: element.action_type_id,
                    sequence: element.sequence,
                    sign_type: element.sign_type,
                    visible: false,
                    department_code: element.staff.department.department_code,
                    staff_name:
                      element.staff.department["name_" + this.$i18n.locale],
                    staff_position:
                      element.staff.position["name_" + this.$i18n.locale],
                    action_type:
                      element.action_type["name_" + this.$i18n.locale],
                    fio: element.staff.employees.length
                      ? this.bosh(element.staff.employees[0][
                          "firstname_" + this.language
                        ]) +
                        "." +
                        this.bosh(element.staff.employees[0][
                          "middlename_" + this.language
                        ]) +
                        ". " +
                        element.staff.employees[0]["lastname_" + this.language]
                      : this.$t("document.vacancy")
                  });
              });
              resp.data.parents.manager_staff.forEach(element => {
                if (
                  !this.document.document_signers.filter(v => {
                    if (
                      v.staff_id == element.manager_staff_id &&
                      v.action_type_id != 4
                    ) {
                      return true;
                    }
                  }).length &&
                  this.document.add_parent
                ) {
                  if (
                    element.manager_staff &&
                    element.manager_staff.employees.length
                  ) {
                    this.document.document_signers.push({
                      id: Date.now() + Math.floor(Math.random() * 1000),
                      document_id: this.document.id,
                      staff_id: element.manager_staff_id,
                      action_type_id: 3,
                      taken_datetime: moment()
                        .add(1, "days")
                        .format("YYYY-MM-DD"),
                      sequence:
                        element.department_type.sequence <= 2 ? 99 : 100,
                      visible:
                        element.department_type.sequence <= 2 ? true : false,
                      department_code: element.department_code,
                      staff_name: element["name_" + this.$i18n.locale],
                      staff_position:
                        element.manager_staff.position[
                          "name_" + this.$i18n.locale
                        ],
                      action_type: this.action_type[this.$i18n.locale],
                      fio: element.manager_staff.employees.length
                        ? this.bosh(element.manager_staff.employees[0][
                            "firstname_" + this.language
                          ]) +
                          "." +
                          this.bosh(element.manager_staff.employees[0][
                            "middlename_" + this.language
                          ]) +
                          ". " +
                          element.manager_staff.employees[0][
                            "lastname_" + this.language
                          ]
                        : this.$t("document.vacancy")
                    });
                  }
                }
              });
              // console.log(this.document);

              setTimeout(() => {
                this.document.document_details[0].content =
                  this.document.document_details[0].content + " ";
              }, 500);
            })
            .catch(err => {
              console.error(err);
            });
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
          this.loading = false;
        });
    },
    getTableList(table_index, table_list_id, search) {
      // console.log(table_index);
      if (table_list_id) {
        this.isLoading = true;
        axios
          .post(this.$store.state.backend_url + "api/document-table-list", {
            table_list_id: table_list_id,
            search: this.searchTable[table_index]
          })
          .then(res => {
            let tableList = res.data.table_list;
            tableList.map(v => {
              let search = "";
              res.data.columns.forEach(colum => {
                colum = colum.replace("locale", this.$i18n.locale);
                search = v[colum] ? search + " " + v[colum] : search;
              });
              v.search = search.trim().replace(/  /g, " ");
              return v;
            });
            this.tableLists[table_index] = tableList;
            // console.log(this.tableLists[table_index]);
            this.isLoading = false;
          })
          .catch(err => {
            console.error(err);
          });
        // console.log(table_index, table_list_id, search);
      }
    },
    getEmployee(key) {
      if (this.employeeTabel[key].length == this.$store.state.tabel_length) {
        this.loading = true;
        this.errorEmp[key] = false;
        this.errorEmpMessage = "";
        this.successMessage = "";
        axios
          .post(this.$store.state.backend_url + "api/employees/get-employee", {
            tabel: this.employeeTabel[key],
            language: this.$i18n.locale == "ru" ? "uz_cyril" : this.$i18n.locale
          })
          .then(res => {
            this.employee = res.data.employee[0];
            this.employee_signers = res.data.parents.manager_staff;
            this.staff = res.data.employee[0].staff[0];
            this.loading = false;
            this.successMessage =
              this.employee.firstname +
              " " +
              this.employee.lastname +
              " " +
              this.employee.middlename;
            this.addEmpBtn[key] = true;
            document.getElementById("addEmployeeTabel" + key).focus();
          })
          .catch(err => {
            console.error(err);
            this.errorEmp[key] = true;
            this.errorEmpMessage = this.$t("tabel_acces_denied");
            document.getElementById("addEmployeeTabel" + key).focus();
            this.loading = false;
          });
      }
    },
    getDocumentFile() {
      this.modalDocumentFile = true;
      axios
        .get(
          this.$store.state.backend_url +
            "api/documents/files/" +
            this.document.id
        )
        .then(res => {
          res.data.forEach(element => {
            if (element.file_name) {
              if (
                !this.documentFiles.find(v => {
                  return v.id == element.id ? 1 : 0;
                })
              ) {
                this.documentFiles.push({
                  id: element.id,
                  file_name: element.file_name
                });
              }
            }
          });
        })
        .catch(err => {
          console.error(err);
        });
    },
    getDocumentList() {
      axios
        .post(this.$store.state.backend_url + "api/documents", {
          search: this.search
        })
        .then(res => {
          this.documentsList = res.data.data;
        })
        .catch(err => {
          console.error(err);
        });
    },
    save() {
      if (this.$refs.documentCreateForm.validate()) {
        this.loading = true;
        let employee_validat = false;
        let document_relation_validat = false;
        if (this.document.has_employee == 1) {
          this.document.document_details.forEach((document_detail, index) => {
            if (document_detail.document_detail_employees.length) {
              employee_validat = true;
            } else {
              employee_validat = false;
              this.errorEmp[index] = true;
              this.errorEmpMessage = this.$t("document.empty_employee");
              this.loading = false;
            }
          });
        } else {
          employee_validat = true;
        }
        if (
          this.document.is_document_relation &&
          !this.documentParents.length
        ) {
          document_relation_validat = false;
          this.errorDocRel = true;
          this.errorDocRelMessage = this.$t("document.empty_document_relation");
          this.loading = false;
        } else {
          document_relation_validat = true;
        }
        if (employee_validat && document_relation_validat) {
          let localStorage = window.localStorage;
          let user = JSON.parse(localStorage.getItem("user"));
          this.document.employee_id = this.chief_employee
            ? this.chief_employee
            : user.employee_id;
          axios
            .post(
              this.$store.state.backend_url + "api/documents/update",
              this.document
            )
            .then(res => {
              console.log(res.data);
              if (!res.data.document_id) {
                Swal.fire({
                  icon: "error",
                  title: "Хатолик...",
                  text: res.data
                  // footer: '<a href="">Why do I have this issue?</a>'
                });
                this.loading = false;
              } else {
                this.doc_id = res.data.document_id;
                this.pdf_file_name = res.data.pdf_file_name;
                // documentga fiyl biriktirish
                axios
                  .post(
                    this.$store.state.backend_url +
                      "api/documents/update-files/" +
                      this.doc_id,
                    this.formData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data"
                      }
                    }
                  )
                  .then(res => {
                    // console.log(res);
                    this.loading = false;
                    // this.$router.push("/documents/list");
                  })
                  .catch(err => {
                    console.error(err);
                    this.loading = false;
                  });
                // documentga document biriktirish
                axios
                  .post(
                    this.$store.state.backend_url +
                      "api/documents/update-document-relation/" +
                      this.doc_id,
                    this.documentParents
                  )
                  .then(res => {
                    // console.log(res);
                    this.loading = false;
                    // this.$router.push("/documents/list");
                  })
                  .catch(err => {
                    console.error(err);
                    this.loading = false;
                  });
                this.loading = false;
                this.$router.push("/document/" + this.pdf_file_name);
              }
            })
            .catch(err => {
              console.error(err);
              this.loading = false;
            });
        }
      }
    },
    deleteEmployee(item, key) {
      this.document.document_details[
        key
      ].document_detail_employees = this.document.document_details[
        key
      ].document_detail_employees.filter(v => {
        if (v.tabel_number != item.tabel_number) {
          return v;
        }
      });
    },
    deleteDocumentDetail(item) {
      this.document.document_details = this.document.document_details.filter(
        v => {
          if (v.id != item.id) {
            return v;
          }
        }
      );
    },
    deleteSigner(item) {
      this.document.document_signers = this.document.document_signers.filter(
        v => {
          if (v.id != item.id) {
            return v;
          }
        }
      );
    },
    deleteFile(item) {
      axios
        .delete(
          this.$store.state.backend_url +
            "api/documents/delete-files/" +
            item.id
        )
        .then(res => {
          Swal.fire("Deleted!", this.$t("swal_deleted"), "success");
        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            title: this.$t("swal_error_title"),
            text: this.$t("swal_error_text")
            //footer: "<a href>Why do I have this issue?</a>"
          });
          console.log(err);
        });
      this.formData.delete(item.file_name);
      this.documentFiles = this.documentFiles.filter(v => {
        if (v.file_name != item.file_name) {
          return v;
        }
      });
    },
    deleteDocumentRelation(item) {
      axios
        .post(
          this.$store.state.backend_url + "api/documents/delete-relation",
          item
        )
        .then(res => {
          Swal.fire("Deleted!", this.$t("swal_deleted"), "success");
        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            title: this.$t("swal_error_title"),
            text: this.$t("swal_error_text")
            //footer: "<a href>Why do I have this issue?</a>"
          });
          console.log(err);
        });
      this.documentParents = this.documentParents.filter(v => {
        if (v.parent_document_id != item.parent_document_id) {
          return v;
        }
      });
    },
    getChiefs(helper_employee_id) {
      axios
        .get(
          this.$store.state.backend_url +
            "api/employees/get-chiefs/" +
            helper_employee_id
        )
        .then(res => {
          this.chiefEmployees = res.data.map(v => {
            return {
              text:
                v["firstname_" + this.$i18n.locale] +
                " " +
                v["lastname_" + this.$i18n.locale],
              value: v.id,
              tabel: v.tabel
            };
          });
        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            title: this.$t("swal_error_title"),
            text: this.$t("swal_error_text")
            //footer: "<a href>Why do I have this issue?</a>"
          });
          console.log(err);
        });
      this.documentParents = this.documentParents.filter(v => {
        if (v.parent_document_id != item.parent_document_id) {
          return v;
        }
      });
    }
  },
  mounted() {
    this.branch();
    this.getStaff();
    this.getDepartments();
    if (this.$store.getters.checkPermission("create_document_for_chief")) {
      let localStorage = window.localStorage;
      let user = JSON.parse(localStorage.getItem("user"));
      this.getChiefs(user.employee_id);
    }
    if (this.$route.params.documentTemplateId) {
      this.documentTitle = this.$t("document.create");
      this.document.id = Date.now();
      this.document.document_template_id = this.$route.params.documentTemplateId;
      this.document.has_employee = 0;
      this.document.document_details = [
        {
          id: Date.now(),
          document_id: this.document.id,
          content: "0",
          document_detail_attribute_values: [],
          document_detail_employees: []
        }
      ];
      this.document.document_date = new Date().toISOString().substr(0, 10);
      this.getDocumentCreate();
    } else if (this.$route.params.documentId) {
      this.documentTitle = this.$t("document.update");
      this.getDocument(this.$route.params.documentId);
    } else {
      this.$router.push("/documents/list");
    }
    this.formData = new FormData();
    this.getDocumentList();
  }
};
</script>
